import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from 'axios'
import logo from "./logo-ijb.png";
import logoYtb from "./youtube.svg";
import logoInsta from "./instagram.svg";
import logoFb from "./facebook.svg";
import logoLinkedin from "./linkedin.svg";
import frase1 from "./frase-1.png";
import frase2 from "./frase-2.png";
import frase3 from "./frase-3.png";
import frase4 from "./frase-4.png";
import frase5 from "./frase-5.png";

import "./App.css";

function App() {
  const [unidadeSelecionada, setUnidadeSelecionada] = useState(null)
  const [showButtons, setShowButtons] = useState(true);

  // TODO : IMPLEMENTAR O BACKEND E FAZER A INTEGRAÇÃO

  const getUnidades = async () => {
    const queryString = await window.location.search;
    const und = await queryString.replace(/[^0-9]/g, "");
    axios.get('https://djbjknwr7g.execute-api.us-west-2.amazonaws.com/api/unidade', {
      headers: {
        'x-api-key': 'M2F19I1ges3Rg6HWsZbknJS1d1girJy5G1ELJtlh'
      }
    }).then((res) => {
      const { data } = res
      setUnidadeSelecionada(_.find(data.unidades, (e) => e.und === und))
      setShowButtons(!!_.find(data.hiddenCities, (e) => Number(e) === Number(und)));
    })
  }

  useEffect(() => {
    getUnidades()
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-title">
          Somos uma das <spam className="App-accent">maiores</spam>
        </p>
        <p className="App-title">entidades formadoras de</p>
        <p className="App-title">aprendizes do Brasil!</p>
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 50 }}
        >
          {!showButtons ? (
            <>
              <img
                src={frase1}
                onClick={() =>
                  (window.location.href =
                    "https://www.institutojoaobittar.edu.br/seja-aprendiz.html")
                }
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  maxWidth: 341,
                  maxHeight: 70,
                  marginBottom: 13,
                }}
                alt="Botão: Você deseja se tornar um aprendiz?"
              />
              <img
                src={frase2}
                onClick={() =>
                  (window.location.href =
                    "https://www.institutojoaobittar.edu.br/seja-empresa-parceira.html")
                }
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  maxWidth: 341,
                  maxHeight: 70,
                  marginBottom: 3,
                }}
                alt="Botão: Sua empresa precisa de aprendizes?"
              />
              <img
                src={frase3}
                onClick={() =>
                  (window.location.href =
                    "https://www.institutojoaobittar.edu.br/")
                }
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  maxWidth: 341,
                  maxHeight: 70,
                  marginBottom: -5,
                }}
                alt="Botão: Visite nosso site"
              />
              <img
                src={frase4}
                onClick={() => (window.location.href = unidadeSelecionada?.whatsapp)}
                style={{
                  cursor: "pointer",
                  marginRight: 15,
                  maxWidth: 341,
                  maxHeight: 70,
                  marginBottom: -5,
                }}
                alt="Botão: Contato via WhatsApp"
              />
            </>
          ) : (
            <img
              src={frase2}
              onClick={() => (window.location.href = unidadeSelecionada?.whatsapp)}
              style={{
                cursor: "pointer",
                marginRight: 15,
                maxWidth: 341,
                maxHeight: 70,
                marginBottom: 3,
              }}
              alt="Botão: Sua empresa precisa de aprendizes?"
            />
          )}
          <a href={`tel:+55${unidadeSelecionada?.ligação}`}>
            <img
              src={frase5}
              style={{
                cursor: "pointer",
                marginRight: 15,
                maxWidth: 341,
                maxHeight: 70,
              }}
              alt="Botão: Contato via ligação"
            />
          </a>
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: 3 }}>
          <h6 className="App-paragraph">
            Siga o Instituto João Bittar nas redes sociais
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: 30,
              padding: "0px 100px",
              marginTop: -15,
            }}
          >
            <img
              onClick={() =>
                (window.location.href =
                  "https://www.youtube.com/c/SocietaFormacaoProfissional")
              }
              src={logoYtb}
              className="App-social"
              alt="logo-youtube"
            />
            <img
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/institutojoaobittar/")
              }
              src={logoInsta}
              className="App-social"
              alt="logo-instagram"
            />
            <img
              onClick={() =>
                (window.location.href =
                  "https://pt-br.facebook.com/institutojoaobittar/")
              }
              src={logoFb}
              className="App-social"
              alt="logo-facebook"
            />
            <img
              onClick={() =>
                (window.location.href =
                  "https://br.linkedin.com/company/institutojoaobittar")
              }
              src={logoLinkedin}
              className="App-social-end"
              alt="logo-linkedin"
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
